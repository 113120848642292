<template>
  <section>
    <b-container>
      <!-- <b-row class="p-main-row">
        <b-col>
          <h2 class="p-main-title  widget-title">
            Pricing custom-tailored for <br />
            every stage of your business
          </h2>
        </b-col>
      </b-row> -->

      <b-row class="toggle-plan cst-padding">
        <b-col>
          <div class="toggle-content">
            <span class="monthly">Monthly Billing</span>
            <div class="custom-toggle">
              <label class="custom__switch">
                <input
                  type="checkbox"
                  v-model="checked"
                  id="month_year_checkbox"
                  name="month_year_checkbox"
                  class="month_year_check"
                  @click="toogleClick(billingType)"
                />
                <span class="custom__slider"></span>
              </label>
            </div>

            <span class="annual">Annual Billing</span>
          </div>

          <span class="save-money">Save up to 20% by paying annually</span>
        </b-col>
      </b-row>

      <!-- card -->
      <b-row class="p-custom-card cst-padding">
        <b-col>
          <div class="pricing-container">
            <div class="pricing-row">
              <!-- card-2 -->
              <div class="price-card column-two">
                <div class="top-border"></div>
                <div class="col-border card-info">
                  <strong>Discover</strong>
                  <h2 v-if="checked" class="card-heading  yearly-text">$32</h2>
                  <h2 v-if="!checked" class="card-heading monthly-text">$39</h2>
                  <h6 v-if="checked" class="sub-text yearly-text">
                    Per mo, billed annually
                  </h6>
                  <h6 v-if="!checked" class="sub-text  monthly-text">
                    per mo
                  </h6>
                </div>
                <div class="prize-contant">
                  <p class="prize-text p2">
                    <span>For solo user who needs a small volume:</span>
                  </p>
                </div>
                <div class="main-dropdown cst-class mb-4">
                  <div class="btn-dropdown">
                    <button type="button" class="dropdown-button">
                      <div class="dropdown__item">
                        <div
                          v-if="!checked"
                          class="mnthly-credits credits-text"
                        >
                          50 Mobile Phone Credits monthly
                        </div>
                        <div
                          v-if="checked"
                          class="mnthly-credits credits-text"
                          @click="getYearlyValue(staticValueForyear)"
                        >
                          600 Mobile Phone Credits yearly
                        </div>
                        <div class="cst-color">
                          1 User
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="middle-content">
                  <p class="card-content" v-if="!checked">
                    3750 work verified emails & enrichment included
                  </p>
                  <p class="card-content" v-if="checked">
                    3750 work verified emails & enrichment included per year
                  </p>
                </div>
                <div class="help-link">
                  <a
                    href="http://help.datagma.com/en/articles/6665031-how-do-credits-works"
                    target="_blank"
                  >
                    <h6>How do credits work</h6>
                    <img src="@/assets/images/help.png" class="help-icon" />
                  </a>
                </div>
                <div class="cst-card-body">
                  <div class="card-btn">
                    <button
                      type="button"
                      class="btn-danger"
                      v-if="!checked"
                      @click="getMonthlyValue(staticValueFormonth)"
                    >
                      Choose this plan
                    </button>
                    <button
                      type="button"
                      class="btn-danger"
                      v-if="checked"
                      @click="getYearlyValue(staticValueForyear)"
                    >
                      Choose this plan
                    </button>
                  </div>
                  <div class="points">
                    <h4 class="points-title">Everything in Free plus:</h4>
                    <ul class="points-head">
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Credits roll-over</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Your unused Credits Will Continue To Roll Over Each
                            Month, For Upto 12 Months, As Long As You Are
                            Subscribed To Datagma</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Free Catchall Emails</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            We only charged you for verified emails</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Bulk Prospecting</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Find people in mass, up to 10, in one click</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Team Management</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Manage your team, add or remove users and check how
                            many credits they spent.</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>CRM Integration</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Push the contact found in Datagma directly to your
                            CRM in one click
                          </span>
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Zapier Integration</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Connect Datagma to more than 3000 saas tools of your
                            stack
                          </span>
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Integromat Integration</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Connect Datagma to more than 2000 saas tools of your
                            stack
                          </span>
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>N8N Integration</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Connect Datagma to more than 2000 saas tools of your
                            stack
                          </span>
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Shared Credit Pool</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#acb2bf"
                            class="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            ></path>
                            <path
                              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                            ></path>
                          </svg>
                          <span class="pricing-tooltiptext">
                            Share all your credits with the members of your team
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- card-3-->
              <div class="price-card column-three">
                <b-alert show>68% of businesses choose Premium </b-alert>
                <div class="card-info">
                  <strong>Premium</strong>

                  <h2 v-if="checked" class="card-heading monthly-text">
                    {{ yearlyRate }}
                  </h2>
                  <h2 v-if="!checked" class="card-heading yearly-text">
                    {{ monthlyRate }}
                  </h2>
                  <h6 v-if="checked" class="sub-text monthly-text">
                    Per mo/ billed annually
                  </h6>
                  <h6 v-if="!checked" class="sub-text yearly-text">
                    Per mo
                  </h6>
                </div>
                <div class="middle-content">
                  <div class="prize-contant">
                    <p class="prize-text p2">
                      <span>How many numbers do you need:</span>
                    </p>
                  </div>

                  <!----dropdown-->
                  <div v-if="!checked">
                    <b-dropdown variant="primary" class="text-dark new">
                      <template #button-content>
                        {{ monthlyselectedValue }}
                        <span class=" cst-color block-text">{{ user }}</span>
                      </template>
                      <b-dropdown-item
                        href="javascript:void(0)"
                        class="text-dark"
                        v-bind:class="j === activeIndex ? 'active' : ''"
                        v-for="(Obj, j) in monthlyoptions"
                        :key="j"
                        @click="
                          setActiveIndex(j);
                          getMonthlyValue(Obj.value);
                        "
                        >{{ Obj.value
                        }}<span class=" cst-color block-text">{{
                          Obj.val
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <p class="card-content monthly-text mt-4">
                      {{ monthlyDescription }}
                    </p>
                  </div>
                  <!----dropdown end-->
                  <!----dropdown-->
                  <div v-if="checked">
                    <b-dropdown variant="primary" class="text-dark new">
                      <template #button-content>
                        {{ yearlyselectedValue }}
                        <span class=" cst-color block-text">{{
                          yearlyuser
                        }}</span>
                      </template>
                      <b-dropdown-item
                        href="javascript:void(0)"
                        class="text-dark"
                        v-bind:class="j === activeIndex ? 'active' : ''"
                        v-for="(Obj, j) in yearlyoptions"
                        :key="j"
                        @click="
                          setActiveIndex(j);
                          getYearlyValue(Obj.value);
                        "
                        >{{ Obj.value
                        }}<span class=" cst-color block-text">{{
                          Obj.val
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <p class="card-content monthly-text">
                      {{ yearlyDescription }}
                    </p>
                  </div>
                  <!----dropdown end-->
                </div>
                <div class="help-link">
                  <a
                    href="http://help.datagma.com/en/articles/6665031-how-do-credits-works"
                    target="_blank"
                  >
                    <h6>How do credits work</h6>
                    <img src="@/assets/images/help.png" class="help-icon" />
                  </a>
                </div>
                <div class="cst-card-body">
                  <div class="card-btn">
                    <button
                      v-if="!checked"
                      type="button"
                      class="btn-danger"
                      @click="paymentLink('month')"
                    >
                      Choose this plan
                    </button>
                    <button
                      v-if="checked"
                      type="button"
                      class="btn-danger"
                      @click="paymentLink('year')"
                    >
                      Choose this plan
                    </button>
                  </div>
                  <div class="points mt-4">
                    <h4 class="points-title">Everything in Discover plus:</h4>
                    <ul class="points-head">
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Credits roll-over</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Your unused Credits Will Continue To Roll Over Each
                            Month, For Upto 12 Months, As Long As You Are
                            Subscribed To Datagma</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Free Catchall Emails</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            We only charged you for verified emails</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Linkedin Scraper</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <a
                            href="https://datagma.com/search-phone-sales-navigator/"
                            ><img src="@/assets/images/icons/tooltip.svg" alt=""
                          /></a>
                          <span class="pricing-tooltiptext">
                            Transform your LinkedIn searches in CSV in a few
                            seconds.</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>CSV Enrichment by File Upload</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Enrich up to 50 000 records in few minutes by
                            uploading a CSV file</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span
                          >Data Enrichment <br />
                          (Lookup and reverse lookup)</span
                        >
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Start from an email or a domain and get the full
                            picture about the person and his company</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Twitter Enrichment</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Get the Twitter account from a LinkedIn Url</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Basic Job Change Detection</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Send a list of people, and we will detect if one of
                            your targets moves to a new company</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>ABM Search (Find People) </span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Send a list of companies with a list of job titles
                            to target. We will find relevant prospects.</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>All API access </span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            - Get access to all our APIs.-</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Clay.run integration</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Integrate with Clay.run the growth co-pilot</span
                          >
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- card-4 -->
              <div class="price-card column-four">
                <div class="top-border"></div>
                <div class="card-info">
                  <strong>Custom</strong>
                  <h2 class="card-heading">Let’s talk</h2>
                  <h6 class="sub-text">Anytime</h6>
                </div>
                <div class="extra-cont">
                  <h5>Need More Credits?</h5>
                  <p>Contact Us</p>
                </div>
                <div class="prize-contant">
                  <p class="prize-text">
                    <span
                      >Custom pricing adapted to your needs, with or without
                      phone numbers, for CRM enrichment or any projects you
                      have.</span
                    >
                  </p>
                </div>
                <!-- <div class="middle-content">
                  <p class="card-content">
                    Unlimited verified emails <br />
                    Custom volume on phone numbers
                  </p>
                </div> -->
                <div class="cst-card-body">
                  <div class="card-btn">
                    <button
                      type="button"
                      class="btn-danger"
                      @click="userTalkWithUsFunc"
                    >
                      Talk with us
                    </button>
                  </div>
                  <div class="points">
                    <h4 class="points-title">Everything in Premium plus:</h4>
                    <ul class="points-head">
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Custom Volume </span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            There is no limit in the number of credits you can
                            buy</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span
                          >Automated Job Change <br />
                          Detection alerts</span
                        >
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            We monitor every job change and inform you in
                            real-time when someone moves to a new company.</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span
                          >Automated HubSpot & Pipedrive <br />
                          Enrichment
                        </span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            We will enrich all the history of your CRM and in
                            real-time all the new contacts.</span
                          >
                        </span>
                      </li>
                      <li>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="7" fill="#003172" />
                          <path
                            d="M8.29664 9.54945C7.50342 10.3488 6.21087 10.3488 5.41765 9.54945L4.40006 8.52405C4.17862 8.30091
                                4.17862 7.94093 4.40006 7.71779C4.62368 7.49245 4.988 7.4922 5.21194 7.71723L5.83143 8.33973C6.39687
                                8.90793 7.31677 8.90729 7.88142 8.3383L10.7855 5.41196C11.0105 5.18519 11.3774 5.18584 11.6016
                                5.4134C11.8224 5.63739 11.8217 5.99729 11.6002 6.22051L8.29664 9.54945Z"
                            fill="white"
                          />
                        </svg>
                        <span>Dedicated Account Manager</span>
                        <span class="pricing-tooltip custom-tooltip">
                          <img src="@/assets/images/icons/tooltip.svg" alt="" />
                          <span class="pricing-tooltiptext">
                            Two hours of consulting calls with one of our
                            experts</span
                          >
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="custom-feature cst-padding">
        <b-col cols="6 ">
          <h2 class="feature-title freq">
            Frequently<br />
            asked questions
          </h2>
          <p class="feature-text-content freq">
            Need More help? Contact <span>help center.</span>
          </p>
        </b-col>
        <b-col cols="6">
          <div class="accordion" role="tablist">
            <b-card
              no-body
              class="mb-1 mt-3 card-freq"
              v-for="Obj in faqArray"
              :key="Obj.id"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle="'accordion-' + Obj.id"
                  >{{ Obj.question }}
                  <img src="@/assets/images/icons/plus-circle.svg" alt=""
                /></b-button>
              </b-card-header>
              <b-collapse
                :id="'accordion-' + Obj.id"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>{{ Obj.answer }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>

      <b-row class="custom-feature cst-padding">
        <b-col>
          <h2 class="feature-title">Features</h2>
          <p class="feature-text-content">
            Get insights to dig down into what’s powerinh your growth the most.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="smart-dashbord">
            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Real-Time data</h4>
              <p>
                Our data is fresher you can find because it does not come from
                databases but is collected live on the web.
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>GDPR Compliant</h4>
              <p>
                We do not have any database and don't store any data to
                guarantee you 100% GDPR compliance.
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>No extra Add-on</h4>
              <p>
                Unlike our competitors, everything is included. there is no
                extra addon to pay for uploading LinkedIn URLs.
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Only verified emails</h4>
              <p>
                All the emails given are verified email addresses. No need to
                use another third-party tool to verify them. They are ready to
                send.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="hand-crafted">
            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Company Data</h4>
              <p>
                Company tags, company locations, the exact number of employees..
                are just an extract of the data we give to you.
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Financial Data</h4>
              <p>
                Annual revenue, total money raised, the number of rounds, last
                funding type, and other exclusive financial data.
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Job Change detection</h4>
              <p>
                We automatically detect when one of your contacts moves to a new
                company, so your database is continually updated.
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Person Data</h4>
              <p>
                Get gender, seniority, role, job title, company, location, and
                the verified work email for every contact.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="hand-new-crafted">
            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Pay only for what you get</h4>
              <p>
                We will not deduct you any credit if we don't found enough data;
                pay only for the data you get
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>File Upload</h4>
              <p>
                Upload your incomplete file; we will fill the blank column in
                minutes. You will receive an email when it's ready!
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>API</h4>
              <p>
                Integrate Datagma technologies in your product. Get your API key
                and access to documentation for free.
              </p>
            </div>

            <div class="smart-new-dasbord">
              <label class="container">
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>

              <h4>Integrations</h4>
              <p>
                Use Zapier or Integromat to enrich any product you want or use
                our native CRM integtation (HubSpot, Pipedrive..)
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="term-hundred">
        <b-col>
          <div class="hundreds-terms">
            <div class="hundreds-terms-hadding">
              <h2 class="widgets">Join the hundreds of teams using Datagma</h2>
            </div>
            <div class="main-pricing-section">
              <div class="product-hunt">
                <img src="@/assets/images/chargebee.png" />
                <img src="@/assets/images/caption data.png" />
              </div>
              <div class="product-hunt-new">
                <img src="@/assets/images/lemlist.png" />
                <img src="@/assets/images/IHS_Markit_logo.svg" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import { GET_CUSTOMERDETAIL } from "../../../../store/constant/actions.type";
import { loadingService } from "../../services/loading.service";

import "vue-select/dist/vue-select.css";
import { analyticsService } from "../../../common/services/analytics.service";
export default {
  name: "newPricingPage",
  data() {
    return {
      user_detail: {},
      activeIndex: 0,
      planID: null,
      planType: false,
      checked: true,
      planSubscribed: null,
      billingType: "annual",
      monthlyRate: "$69",
      yearlyRate: "$55",
      priceAmount: "",
      user: "For 1 User",
      yearlyuser: "For 1 User",
      monthlyDescription: "8250 work verified emails & enrichment included",
      yearlyDescription:
        "99 000 work verified emails & enrichment included per year",
      monthlyselectedValue: "110 Mobile Phone Credits",
      yearlyselectedValue: "1320 Mobile Phone Credits",
      staticValueFormonth: "50 Mobile Phone Credits",
      staticValueForyear: "600 Mobile Phone Credits",
      monthlyoptions: [
        { value: "110 Mobile Phone Credits", val: "For 1 User" },
        { value: "230 Mobile Phone Credits", val: "2 Users included" },
        { value: "350 Mobile Phone Credits", val: "3 Users included" },
        { value: "470 Mobile Phone Credits", val: "4 Users included" },
        { value: "600 Mobile Phone Credits", val: "5 Users included" },
      ],
      yearlyoptions: [
        { value: "1320 Mobile Phone Credits", val: "For 1 User" },
        { value: "2760 Mobile Phone Credits", val: "2 Users included" },
        { value: "4200 Mobile Phone Credits", val: "3 Users included" },
        { value: "5640 Mobile Phone Credits", val: "4 Users included" },
        { value: "7200 Mobile Phone Credits", val: "5 Users included" },
      ],
      monthlyId: [
        { value: "3750_monthly_new" },
        { value: "8250_monthly_new" },
        { value: "17250_monthly_new" },
        { value: "26250_monthly_new" },
        { value: "35250_monthly_new" },
        { value: "45000_monthly_new" },
      ],
      yearlyId: [
        { value: "3750_yearly_new" },
        { value: "8250_yearly_new" },
        { value: "17250_yearly_new" },
        { value: "26250_yearly_new" },
        { value: "35250_yearly_new" },
        { value: "45000_yearly_new" },
      ],
      faqArray: [
        {
          id: "target1",
          question: "How Datagma credits works?",
          answer:
            "Credits are a system of currency at Datagma. Datagma credits can be used for both email and phone searches. 1 credit = 1 email and associated company and person data (enrichment data) 75 credits = 1 mobile phone number Once you buy a package of credits, you spend your credits the way you want for emails or phone numbers. For example, if you take a package with 110 phone numbers, that means you have 8250 credits, you can search 8250 emails or 110 phones (8250/75 = 110), or 4125 emails and 55 phones (4125 credits used to find the phone, and the remaining 4125 credits used to find 55 phones).",
        },
        {
          id: "target2",
          question: "What is the difference between price plans?",
          answer:
            "Each plan offers a different number of credits and features, depending on your company’s needs",
        },
        {
          id: "target3",
          question: "Why can't I sign up to Datagma with my email address?",
          answer:
            "To sign up for Datagma all you need is a business email address, which looks like this: john@pearrr.com. If you find you’re not able to sign up to Datagma, it’s probably because you’re using Gmail, Yahoo, Hotmail, or general business emails like admin@acme.com, sales@acme.com, or info@acme.com (which Datagma does not accept.) If you have more questions, feel free to reach out to our friendly support team.",
        },
        {
          id: "target4",
          question:
            "Do you have any larger plans, or plans for large team sizes?",
          answer:
            "Absolutely. Datagma has plans for teams of every size. If you’re 5+ users, please contact our sales team to discuss your business needs. We’d love to create your perfect plan.",
        },
        {
          id: "target5",
          question: "Can I cancel my subscription?",
          answer:
            "You can cancel the renewal of your plan at any time for any reason. Click on my account, go to the billing tab, and click ‘cancel’.",
        },
        {
          id: "target6",
          question: "Does Datagma integrate with my CRM?",
          answer:
            "Datagma integrates with HubSpot and Pipedrive with a two ways enrichment. You can also build connections to any CRM thanks to your Zapier, N8N, and Make integrations. Contact us for any requests.",
        },
        {
          id: "target7",
          question: "Is Datagma's data GDPR aligned & CCPA compliant?",
          answer:
            "Yes, our data is fully committed and aligned to the GDPR & CCPA requirements.",
        },
        {
          id: "target8",
          question: "Do unused credits roll over to the next month?",
          answer:
            "Yes, your unused credits will continue to roll over each month, for up to 12 months, as long as you are subscribed to Datagma. On annual plans, you will get all your credits upon purchase. Any unused credits will be reset at the end of the annual cycle.",
        },
      ],
    };
  },
  methods: {
    setActiveIndex(index) {
      this.activeIndex = index;
    },
    toogleClick(callFor) {
      this.activeIndex = 0;
      this.checked = !this.checked;
      if (callFor == "monthly") {
        this.billingType = "annual";
      }
      if (callFor == "annual") {
        this.billingType = "monthly";
      }
    },
    encryptPlanID(plan_id) {
      const encryptedText = this.$CryptoJS.AES.encrypt(
        plan_id,
        "123drgkner"
      ).toString();
      return encryptedText;
    },
    async decryptPlanID(encryptedText) {
      return new Promise((resolve) => {
        const decryptedBytes = this.$CryptoJS.AES.decrypt(
          encryptedText,
          "123drgkner"
        );
        const decryptedText = decryptedBytes.toString(this.$CryptoJS.enc.Utf8);
        resolve(decryptedText);
      });
    },
    getMonthlyValue(val) {
      if (val == "50 Mobile Phone Credits") {
        this.planID = this.encryptPlanID("3750_monthly_new");
        this.monthlyselectedValue = null;
        this.monthlyRate = "$39";
        this.paymentLink("month");
      }
      if (val == "110 Mobile Phone Credits") {
        this.monthlyselectedValue = "110 Mobile Phone Credits";
        this.user = "For 1 User";
        this.monthlyRate = "$69";
        this.monthlyDescription =
          "8250 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("8250_monthly_new");
      }
      if (val == "230 Mobile Phone Credits") {
        this.monthlyselectedValue = "230 Mobile Phone Credits";
        this.user = "2 Users included";
        this.monthlyRate = "$138";
        this.monthlyDescription =
          "17250 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("17250_monthly_new");
      }
      if (val == "350 Mobile Phone Credits") {
        this.monthlyselectedValue = "350 Mobile Phone Credits";
        this.user = "3 Users included";
        this.monthlyRate = "$207";
        this.monthlyDescription =
          "26250 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("26250_monthly_new");
      }
      if (val == "470 Mobile Phone Credits") {
        this.monthlyselectedValue = "470 Mobile Phone Credits";
        this.user = "4 Users included";
        this.monthlyRate = "$276";
        this.monthlyDescription =
          "35250 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("35250_monthly_new");
      }
      if (val == "600 Mobile Phone Credits") {
        this.monthlyselectedValue = "600 Mobile Phone Credits";
        this.user = "5 Users included";
        this.monthlyRate = "$345";
        this.monthlyDescription =
          "45000 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("45000_monthly_new");
      }
    },
    getYearlyValue(val) {
      this.yearlyRate = "";
      if (val == "600 Mobile Phone Credits") {
        this.planID = this.encryptPlanID("3750_yearly_new");
        this.yearlyselectedValue = null;
        this.yearlyRate = "$32";
        this.paymentLink("year");
      }
      if (val == "1320 Mobile Phone Credits") {
        this.yearlyselectedValue = "1320 Mobile Phone Credits";
        this.yearlyuser = "For 1 User";
        this.yearlyRate = "$55";
        this.yearlyDescription =
          "99 000 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("8250_yearly_new");
      }
      if (val == "2760 Mobile Phone Credits") {
        this.yearlyselectedValue = "2760 Mobile Phone Credits";
        this.yearlyuser = "2 Users included";
        this.yearlyRate = "$110";
        this.yearlyDescription =
          "207 000 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("17250_yearly_new");
      }
      if (val == "4200 Mobile Phone Credits") {
        this.yearlyselectedValue = "4200 Mobile Phone Credits";
        this.yearlyuser = "3 Users included";
        this.yearlyRate = "$166";
        this.yearlyDescription =
          "315 000 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("26250_yearly_new");
      }
      if (val == "5640 Mobile Phone Credits") {
        this.yearlyselectedValue = "5640 Mobile Phone Credits";
        this.yearlyuser = "4 Users included";
        this.yearlyRate = "$220";
        this.yearlyDescription =
          "423 000 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("35250_yearly_new");
      }
      if (val == "7200 Mobile Phone Credits") {
        this.yearlyselectedValue = "7200 Mobile Phone Credits";
        this.yearlyuser = "5 Users included";
        this.yearlyRate = "$275";
        this.yearlyDescription =
          "540 000 work verified emails & enrichment included";
        this.planID = this.encryptPlanID("45000_yearly_new");
      }
    },
    paymentLink(type) {
      if (this.billingType == "monthly") {
        this.priceAmount = this.monthlyRate;
      } else {
        this.priceAmount = this.yearlyRate;
      }
      let trackobj = {
        email: localStorage.getItem("user_email"),
        amount: this.priceAmount,
        period: this.billingType,
      };
      // let checkSubscription = this.decryptPlanID(this.planID);
      if (type == "month") {
        if (this.monthlyselectedValue == "110 Mobile Phone Credits") {
          this.planID = this.encryptPlanID("8250_monthly_new");
          this.checkPlan();
        } else {
          analyticsService.userPaymentUpgrade(this.user_detail, trackobj);
          this.checkPlan();
        }
      } else {
        if (this.yearlyselectedValue == "1320 Mobile Phone Credits") {
          this.planID = this.encryptPlanID("8250_yearly_new");
          analyticsService.userPaymentUpgrade(this.user_detail, trackobj);
          this.checkPlan();
        } else {
          analyticsService.userPaymentUpgrade(this.user_detail, trackobj);
          this.checkPlan();
        }
      }
    },
    userTalkWithUsFunc() {
      analyticsService.userTalkWithUs(this.user_detail);
    },
    getSubscription() {
      loadingService.setloader(true);
      this.$store.dispatch(GET_CUSTOMERDETAIL).then((data) => {
        if (data?.list.length > 0) {
          this.planSubscribed = data.list[0]?.subscription?.plan_id;
          loadingService.setloader(false);
        } else {
          console.log("No Subscription Found");
        }
      });
      loadingService.setloader(false);
    },

    async checkPlan() {
      this.decryptPlanID(this.planID).then((res) => {
        if (this.planSubscribed && this.planSubscribed === res) {
          this.flashMessage.error({
            message:
              "we cannot process your subscription request as you are already subscribed to the selected plan",
          });
        } else {
          this.$router.push({
            name: "paymentLink",
            params: { id: this.planID },
          });
        }
      });
    },
  },

  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    this.$eventBus.$emit("callCreditMethod");
    this.getSubscription();
  },

  mounted() {},

  computed: {},
};
</script>

<style>
.cst-padding {
  padding-bottom: 70px;
}

.p-main-row {
  margin-bottom: 50px;
}

.p-main-row .p-main-title {
  max-width: 1060px;
  margin: 0 auto 80px;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800 !important;
  font-size: 64px;
  line-height: 78px;
  text-align: left;
  color: rgb(0 49 114);
}

/*====================================
            Pricing NEW CSS
 ================================= */
.p-custom-card {
  margin-bottom: 75px;
}

.p-custom-card strong {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333334;
  border-radius: 4px;
}

.p-custom-card .pricing-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 35px 0;
  gap: 30px;
}

.p-custom-card .price-card {
  padding: 28px;
  background: #ffffff;
  border: 1px solid #dfe1e6;
  border-radius: 8px;
  position: relative;
  width: 400px;
}

.p-custom-card .top-border {
  position: absolute;
  width: 100%;
  height: 8px;
  left: 0px;
  top: -1px;
  background: #ffae58;
  border-radius: 0px 0px 8px 8px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.p-custom-card .card-header {
  position: absolute;
  left: 0px;
  top: -1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #fff;
  background: #2589ff;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
}

.p-custom-card .price-card .card-heading {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #333334;
  margin: 24px 0 8px;
}

.p-custom-card .price-card .sub-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #99a0a7;
  margin: 0;
  margin-bottom: 24px;
}

.p-custom-card .middle-content,
.card-btn {
  margin-bottom: 24px;
}

.p-custom-card .card-content {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #10234c;
  padding: 0;
  margin-top: 30px;
  /* border-top: 1px solid #ebedf2;
  border-bottom: 1px solid #ebedf2; */
}

.p-custom-card .price-card button:disabled {
  background: #f2f3f4;
  border-radius: 10px;
  color: #a5adba;
  cursor: not-allowed;
}

.p-custom-card .btn-danger {
  border: none;
  padding: 9px 16px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  background: #2589ff;
  border-radius: 8px;
}

.p-custom-card .btn-danger:hover {
  transition: 0.5s;
  background-color: #003172;
  border-color: #003172;
}

.p-custom-card .points-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #101528;
  margin: 0;
}

.p-custom-card .points-head {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.p-custom-card .points-head li {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #101528;
  display: flex;
  align-items: center;
  gap: 12px;
}

.p-custom-card .price-card.col-border {
  border: 3px solid #2589ff;
  transform: scale(1.09);
  margin-bottom: 30px;
}

.custom-feature-content .feature-text-content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #7a869a;
}

.custom-feature-content .feature-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: #172b4d;
}

/* Toggle button */
.toggle-plan {
  margin-bottom: 40px;
}

.toggle-plan .toggle-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-plan .toggle-content span.monthly {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #10234c;
}

.toggle-plan .toggle-content .annual {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #7a869a;
}

.toggle-plan .custom-toggle .custom__switch input {
  display: none;
}

.toggle-plan .custom-toggle .custom__switch {
  display: inline-block;
  width: 55px;
  height: 20px;
  margin: 4px;
  /* transform: translateY(50%); */
  position: relative;
}

.toggle-plan .custom-toggle .custom__slider {
  position: absolute;
  top: 7px;
  bottom: -4px;
  left: 10px;
  right: 10px;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 1px solid transparent;
  overflow: hidden;
  transition: 0.2s;
}

.toggle-plan .custom-toggle .custom__slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #777;
  border-radius: 30px;
  transform: translateX(-17px);
  transition: 0.2s;
}

.toggle-plan .custom-toggle input:checked + .custom__slider:before {
  transform: translateX(17px);
  background-color: #2589ff;
}

.toggle-plan .custom-toggle input:checked + .custom__slider {
  box-shadow: 0 0 0 2px #2589ff, 0 0 8px #2589ff;
}

.toggle-plan .save-money {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2589ff;
  margin-top: 16px;
}

/* card */

/* feature */
.smart-dashbord {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.smart-new-dasbord {
  width: 25%;
  margin-top: 45px;
  padding-right: 20px;
}

.smart-new-dasbord .radiobtn {
  background-color: #2196f3;
}

.radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.main-range {
  width: 88%;
  padding: 14px;
  margin-bottom: 32px;
  background-color: #fafafa;
}

.number-range ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-left: 0px;
  margin-right: -20px;
}

.number-range ul li {
  float: right;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container p {
  color: #7a869a;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -32px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f2edfe;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.hand-new-crafted {
  display: flex;
}

.hand-crafted {
  display: flex;
}

.hundreds-terms-hadding h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #172b4d;
  margin-top: 141px;
}

.feature-section {
  margin-top: 96px;
  margin-bottom: 96px;
}

.product-box {
  display: flex;
}

.product-hunt {
  display: flex;
  justify-content: space-evenly;
  padding-top: 51px;
  align-items: center;
}

.billing-type ul {
  display: flex;
  justify-content: end;
  list-style: none;
  margin: auto 34px;
}

.product-box h2 {
  background-color: #da552f;
  color: #fff;
  width: 52px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding-top: 4px;
}

.billing-type a.active {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  background-color: #2589ff;
  padding: 7px;
  border-radius: 12px;
}

.billing-type a {
  color: #7a869a;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.product-box p {
  color: #da552f;
  font-size: 20px;
  margin: 13px 10px;
  font-weight: 700;
}

.product-hunt-new {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  align-items: center;
}

.main-pricing-section {
  max-width: 600px;
  margin: auto;
}

.main-pricing-section img {
  max-width: 250px;
  margin: 10px 60px;
}

.main-range .slider {
  margin-top: -70px;
}

.price-card.column-three {
  border-radius: 0px 0px 8px 8px;
}

.alert-info {
  position: absolute;
  top: -46px;
  left: -3px;
  z-index: 999;
  background: rgb(37 137 255);
  border: none;
  color: rgb(255 255 255);
  padding: 10px;
  width: 400px;
  border-radius: 8px 8px 0 0;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

body h2.feature-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  color: rgb(23 43 77);
}

.feature-text-content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgb(122 134 154);
}

.term-hundred .hundreds-terms {
  padding-top: 98px;
}

.term-hundred .hundreds-terms .widgets {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #172b4d;
}

@media (max-width: 767px) {
  .price-card.column-three {
    margin-top: 40px;
  }

  .alert-info {
    width: 100%;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 100%;
  }
}

.p-custom-card .price-card button:disabled {
  background: rgb(242 243 244);
  border-radius: 10px;
  color: rgb(165 173 186);
  cursor: not-allowed;
}

.main-dropdown.cst-class {
  border: none;
}

.main-dropdown .dropdown-button {
  width: 100%;
  text-align: left;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgb(242 242 242);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  background: rgb(0 0 0 / 0%);
}

.main-dropdown .dropdown__item {
  color: rgb(0 0 0);
  font-weight: 600;
}

.cst-color {
  color: rgb(53 53 53);
  font-weight: 400;
  padding-left: 0px;
}

.price-card .extra-cont h5 {
  font-size: 16px;
  margin: 0 0 4px;
  color: rgb(0 49 114);
}

.price-card .extra-cont p {
  color: rgb(53 53 53);
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 24px;
}

.prize-contant p {
  font-size: 14px;
  color: rgb(0 49 114);
  font-weight: 600;
}

p.prize-text.p2 {
  font-size: 12px;
}

.main-dropdown .inner_layout_cst {
  position: absolute;
  top: 60px;
  left: -1px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 5px;
  padding: 10px;
  background: rgb(255 255 255);
  z-index: 99999;
  width: 100%;
}

.main-dropdown .inner_layout_cst ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-dropdown .inner_layout_cst ul li {
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px;
}

.main-dropdown .dropdown__item {
  color: rgb(0 0 0);
  font-weight: 600;
}

.main-dropdown.active-dropdown {
  border: 1px solid rgb(37 137 255);
  border-radius: 8px;
}

.main-dropdown {
  position: relative;
  overflow: visible;
  margin-bottom: 24px;
}

.dropdown-menu.show li.active a,
.dropdown-menu.show li:hover a {
  background: rgb(37 137 255);
  color: rgb(255 255 255) !important;
  cursor: pointer !important;
  border-radius: 8px;
}

.main-dropdown .inner_layout_cst ul li.active .cst-color,
.inner_layout_cst ul li:hover .cst-color {
  color: rgb(255 255 255);
}

.custom-tooltip {
  cursor: pointer;
}

.pricing-tooltip {
  position: relative;
  display: inline-block;
}

.pricing-tooltip .pricing-tooltiptext {
  visibility: hidden;
  width: 120px;
  color: rgb(255 255 255);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 30px;
  text-transform: capitalize;
  background-color: rgb(37 137 255);
  border-radius: 5px;
  padding: 0.5em;
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 16px;
}

.pricing-tooltiptext::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 9px;
  left: -6px;
  width: 5px;
  height: 5px;
  border: 6px solid rgb(37 137 255);
  transform: rotate(45deg);
}

.pricing-tooltip:hover .pricing-tooltiptext {
  visibility: visible;
  animation: animate 0.5s forwards;
}

.price-card.column-three {
  border: 3px solid rgb(37 137 255);
  border-radius: 0 0 8px 8px;
}

.price-card .b-dropdown.btn-group {
  width: 100%;
}

.price-card .dropdown-toggle.btn-primary,
.price-card .b-dropdown.show .dropdown-toggle.btn-primary {
  width: 100%;
  text-align: left;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgb(242 242 242);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  background: rgb(0 0 0 / 0%);
  color: rgb(0 0 0);
  white-space: normal;
  box-shadow: none;
}

.price-card .b-dropdown.show .dropdown-toggle.btn-primary {
  border: 1px solid rgb(37 137 255);
}

.price-card .dropdown-menu {
  padding: 12px;
  width: 100%;
  transform: unset !important;
  top: 63px !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 5px;
  padding: 10px;
  background: rgb(255 255 255);
  z-index: 99999;
  width: 100%;
  border: none;
}

.price-card .dropdown-menu li {
  padding: 0px;
}

.price-card .dropdown-menu li a {
  display: block;
  white-space: normal;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
  width: 99%;
}

.block-text {
  display: block;
}

.btn-primary.dropdown-toggle::after {
  position: absolute;
  right: 16px;
  top: 27px;
}

.b-dropdown.btn-group.show .btn-primary.dropdown-toggle::after {
  transform: rotate(180deg);
}

button.btn.btn-secondary.collapsed {
  background: rgb(0 0 0 / 0%);
  border: none;
  color: rgb(0 0 0);
  color: rgb(0 49 114);
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  outline: none;
  box-shadow: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.btn.btn-secondary.not-collapsed {
  background: rgb(0 0 0 / 0%);
  border: none;
  color: rgb(0 0 0);
  color: rgb(0 49 114);
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  outline: none;
  box-shadow: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.not-collapsed img {
  transform: rotate(135deg);
}

header.card-header img {
  width: 20px;
  height: 20px;
  transition: all 0.2s;
}

.custom-feature h2.feature-title.freq {
  color: rgb(0 49 114);
  font-size: 48px;
}

p.accordion-cst-content {
  font-size: 20px;
}

p.feature-text-content.freq span {
  color: rgb(37 137 255);
  font-weight: 600;
}

header.card-header {
  background: rgb(242 243 244);
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid rgb(0 0 0 / 13%);
}

p.card-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: rgb(53 53 53);
}
.help-icon {
  height: 15px;
  width: 15px;
}
.help-link a {
  display: flex;
  align-items: center;
  gap: 5px;
}
.help-link h6 {
  margin-bottom: 0;
}
.help-link {
  margin-bottom: 15px;
}
/* .card.card-freq {
  border-radius: 7px !important;
} */
</style>
