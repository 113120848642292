
import VueJwtDecode from 'vue-jwt-decode'
// D:\Projects\razot\datagma\datagama\src\app\auth\signIn\signin.vue
// import analyticsServicessssssss from '../../../auth/signIn/signin.vue'
export const analyticsService = {

    userLogin(user_detail) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('User Login', {
            email: user_detail.email,
            userId: user_detail.email,

        }, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    userGroup(user_detail) {

        let name = '';

        let decoded = VueJwtDecode.decode(localStorage.getItem('access_token'));
        if (decoded && decoded.companyName)
            name = decoded.companyName;
        window.analytics.group(user_detail.email.split('@')[1], {
            email: user_detail.email,
            name: name,
            website: user_detail.email.split('@')[1]
        }, {
            page: {
                context: {
                    groupId: user_detail.email.split('@')[1]
                },
            }
        })
    },
    connectHubspot(user_detail, data) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('User HubSpot connect', data, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    connectPipeDrive(user_detail) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode

        })
        window.analytics.track('User PipeDrive connect', {
            email: user_detail.email,
            userId: user_detail.email,
        }, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    connectFileUpload(user_detail, data) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('User File Upload', data, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    connectHubSpotJobChange(user_detail) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('User Hubspot Job Change', {
            email: user_detail.email,
            userId: user_detail.email,
        }, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    userPaymentUpgrade(user_detail, resource) {
        // let email = localStorage.getItem('user_email').split('@')[1]
        window.analytics.identify(resource.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('FE_click_on_upgrade', {
            ...resource
        }, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    userTalkWithUs(user_detail) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('User_talk_with_us', {
            email: user_detail.email,
        }, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    userPayementSuccess(user_detail, resource) {
        window.analytics.identify(resource.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('FE User Payment Success', resource, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    userSignup(resource) {
        window.analytics.identify(resource.email, {
            userId: resource.email,
            firstName: resource.firstName,
            lastName: resource.lastName,
            name: resource.firstName + ' ' + resource.lastName,
            email: resource.email,
            company: {
                id: resource.email.split('@')[1],
                name: resource.companyName,
            },
            companyName: resource.companyName,
            referralCode: resource.referralCode
            // context: {
            //     groupId: localStorage.getItem('user_email').split('@')[1]
            // },
            // name: localStorage.getItem("first_name") + ' ' + localStorage.getItem("last_name"),
        });
        window.analytics.track('User Sign up', {
            firstName: resource.firstName,
            lastName: resource.lastName,
            email: resource.email,
            userId: resource.email,
        }, {
            context: {
                groupId: resource.email.split('@')[1]
            },
        });
    },
    userGetstarted(callFor, data, user_detail) {

        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode

            // context: {
            //     groupId: localStorage.getItem('user_email').split('@')[1]
            // },
        })
        if (callFor == "step1") {
            window.analytics.track('step1', {
                UserId: user_detail.email,
                PhoneNumbers: data.PhoneNumbers,
                Email: data.Email,
                CompanyData: data.CompanyData,
                JobChanges: data.JobChanges,
                context: {
                    groupId: user_detail.email.split('@')[1]
                }
            },
            );
        }
        else if (callFor == "step2") {
            window.analytics.track('step2', {
                UserId: user_detail.email,
                FileUpload: data.FileUpload,
                API: data.API,
                Integrations: data.Integrations,
                ChromeExtensionAndSearchEngine: data.ChromeExtensionAndSearchEngine,
                context: {
                    groupId: user_detail.email.split('@')[1]
                },
            }, {
                page: {
                    context: {
                        groupId: user_detail.email.split('@')[1]
                    },
                }
            }
            );
        } else {
            window.analytics.track("step3", {
                UserId: user_detail.email,
                hubspot: data.hubspot,
                zapier__make: data.zapier__make,
                sales_navigator: data.sales_navigator,
                // SurfeClay: data.SurfeClay,
                // Pipedrive: data.Pipedrive,
                // NotFinded: data.NotFinded,
                context: {
                    groupId: user_detail.email.split('@')[1]
                }
            }, {
                page: {
                    context: {
                        groupId: user_detail.email.split('@')[1]
                    },
                }
            });
            //  window.analytics.track("OnBoarding", {
            //     UserId: localStorage.getItem('user_email'),
            //     hubspot: data.hubspot,
            //     zapier__make: data.zapier__make,
            //     sales_navigator: data.sales_navigator,
            //     // SurfeClay: data.SurfeClay,
            //     // Pipedrive: data.Pipedrive,
            //     // NotFinded: data.NotFinded,
            //     context: {
            //         groupId: localStorage.getItem('user_email').split('@')[1]
            //     }
            // }, {
            //     page: {
            //         context: {
            //             groupId: localStorage.getItem('user_email').split('@')[1]
            //         },
            //     }
            // });
        }
    },
    userGetstartedOnBoardig(callFor, firstPageData, secondPageData, thirdPageData) {
        window.analytics.track("onboarding", {
            PhoneNumbers: firstPageData.PhoneNumbers,
            Email: firstPageData.Email,
            CompanyData: firstPageData.CompanyData,
            JobChanges: firstPageData.JobChanges,
            FileUpload: secondPageData.FileUpload,
            API: secondPageData.API,
            Integrations: secondPageData.Integrations,
            ChromeExtensionAndSearchEngine: secondPageData.ChromeExtensionAndSearchEngine,
            hubspot: thirdPageData.hubspot,
            zapier__make: thirdPageData.zapier__make,
            sales_navigator: thirdPageData.sales_navigator,
            // SurfeClay: data.SurfeClay,
            // Pipedrive: data.Pipedrive,
            // NotFinded: data.NotFinded,
            context: {
                groupId: localStorage.getItem('user_email').split('@')[1]
            }
        }, {
            page: {
                context: {
                    groupId: localStorage.getItem('user_email').split('@')[1]
                },
            }
        });
    },
    userCancelSubscription(callFor, data, user_detail) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        if (callFor == "step1") {
            window.analytics.track('Cancel Subscription Step1', {
                UserId: localStorage.getItem('user_email'),
                NotEnoughData: data.notEnoughData,
                TooComplicated: data.tooComplicated,
                NoTimeToOptimise: data.noTimeToOptimise,
                ProjectFinished: data.projectFinished,
                PoorFunctionality: data.poorFunctionality,
                TooExpensive: data.tooExpensive,
                GoWithProvider: data.goWithProvider,
                NotMeetOurExpections: data.notMeetOurExpections,
                Other: data.other,
                context: {
                    groupId: localStorage.getItem('user_email').split('@')[1]
                }
            }, {
                page: {
                    context: {
                        groupId: localStorage.getItem('user_email').split('@')[1]
                    },
                }
            }
            );
        }
        else if (callFor == "cancelStep") {
            window.analytics.track('Cancel Subscription Step2 is Cancelled by user', {
                UserId: localStorage.getItem('user_email'),
                context: {
                    groupId: localStorage.getItem('user_email').split('@')[1]
                },
            })
        }
        else if (callFor == "pauseStep") {
            window.analytics.track('Pause Subscription', {
                UserId: localStorage.getItem('user_email'),
                KeepXxCredits: data.keepXxCredits,
                NotEraseData: data.notEraseData,
                LimitedChargeFee: data.limitedChargeFee,
                context: {
                    groupId: localStorage.getItem('user_email').split('@')[1]
                },

            }, {
                page: {
                    context: {
                        groupId: localStorage.getItem('user_email').split('@')[1]
                    },
                }
            })
        }
        else {
            window.analytics.track("Cancel Subscription Step2", {
                UserId: localStorage.getItem('user_email'),
                CompletelyErashed: data.completelyErashed,
                NotAccessAnymore: data.notAccessAnymore,
                CreditLost: data.creditLost,
                NumberOfCredits: data.numberOfCredits,
                context: {
                    groupId: localStorage.getItem('user_email').split('@')[1]
                }
            }, {
                page: {
                    context: {
                        groupId: localStorage.getItem('user_email').split('@')[1]
                    },
                }
            });
        }
    },
    userPhoneNumberAvailable(user_detail) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('Phone number available', { UserId: user_detail.email }, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    },
    getUploadedProperty(user_detail, data) {
        window.analytics.identify(user_detail.email, {
            email: user_detail.email,
            company: {
                id: user_detail.email.split('@')[1],
                name: user_detail.companyName
            },
            firstName: user_detail.firstName,
            lastName: user_detail.lastName,
            fullName: user_detail.fullName,
            name: user_detail.fullName,
            currentCredit: user_detail.currentCredit,
            companyName: user_detail.companyName,
            signupCode: user_detail.signupCode
        })
        window.analytics.track('click on file upload', data, {
            context: {
                groupId: user_detail.email.split('@')[1]
            },
        });
    }
}